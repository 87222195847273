<template>
  <div>
    <el-tabs class="mt-4" type="border-card">
      <el-tab-pane :label="$t('AIQuestions.Computer Preview')">
        <span slot="label"
          ><i class="fas fa-laptop"></i>
          {{ $t("AIQuestions.Computer Preview") }}</span
        >
        <div class="tab-pc" ref="tab-pc">
          <MultipleChoiceWithPassageACT
            :id="1"
            ref="MultipleChoiceWithPassage"
            order="1"
            :questionId="question.id"
            :testType="1"
            :canHighlighter="false"
            :canEliminator="false"
            :canMasking="false"
            :passageContent="passage.content"
            questionOrder="1"
            :content="question.content"
            :options="question.options"
            :answer="``"
            :tags="question.question_tags ? question.question_tags : []"
            :activities="[]"
            :isCorrect="true"
            :correctAnswer="
              question.answers && question.answers.length > 0
                ? question.answers[0].answer[0]
                : ''
            "
            :explanation="
              question.explanations.length > 0
                ? question.explanations[0].explanation
                : ''
            "
            mode="preview"
          ></MultipleChoiceWithPassageACT>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('AIQuestions.Mobile Preview')">
        <span slot="label"
          ><i class="fas fa-mobile-alt"></i>
          {{ $t("AIQuestions.Mobile Preview") }}</span
        >
        <div class="tab-phone-box" ref="tab-phone-box">
          <div class="tab-phone">
            <MultipleChoiceWithPassageACT
              v-if="question"
              :id="1"
              ref="MultipleChoiceWithPassage"
              :questionId="question.id"
              :testType="1"
              :canHighlighter="false"
              :canEliminator="false"
              :canMasking="false"
              :passageContent="passage.content"
              questionOrder="1"
              :content="question.content"
              :options="question.options"
              :answer="question.answer"
              :tags="question.question_tags ? question.question_tags : []"
              :activities="[]"
              :isCorrect="true"
              :correctAnswer="
                question.answers && question.answers.length > 0
                  ? question.answers[0].answer[0]
                  : ''
              "
              :explanation="
                question.explanations.length > 0
                  ? question.explanations[0].explanation
                  : ''
              "
              :isPhone="true"
              mode="preview"
            ></MultipleChoiceWithPassageACT>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import MultipleChoiceWithPassageACT from "@/views/act/actResult/components/MultipleChoiceWithPassage";

export default {
  components: { MultipleChoiceWithPassageACT },

  mixins: [],

  props: ["showQuestion", "showPassage"],
  data() {
    return {
      showPhone: false,
      showPC: false
    };
  },
  computed: {
    question() {
      return JSON.parse(JSON.stringify(this.showQuestion));
    },
    passage() {
      return JSON.parse(JSON.stringify(this.showPassage));
    }
  },
  watch: {
    question: {
      deep: true,
      handler() {
        this.initMathJaxPlugin();
      }
    }
  },

  mounted() {
    this.initMathJaxPlugin();
  },

  methods: {
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.MathQueue([this.$refs["tab-phone"]]);
        this.$mathJax.MathQueue([this.$refs["tab-pc"]]);
      });
    }
  }
};
</script>

<style scoped>
.show-phone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
  z-index: 999;
}
.closed {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  z-index: 1000;
  position: fixed;
  top: 80px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: #606266;
}
.phone {
  position: fixed;
  width: 390px;
  height: 884px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 80px auto 0;
  z-index: 1000;
  background-color: white;
  overflow: auto;
  padding-top: 1rem;
  border-radius: 1rem;
  padding-bottom: 1rem;
}
.show-pc {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
  z-index: 999;
}
.pc {
  position: fixed;
  width: 1280px;
  height: 720px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 80px auto 0;
  z-index: 1000;
  background-color: white;
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.tab-phone-box {
  /* opacity: 0.5; */
  background: #000;
  padding: 1rem 0;
}
.tab-phone {
  width: 390px;
  height: 884px;
  margin: 0 auto;
  background-color: white;
  overflow: auto;
  padding-top: 1rem;
  border-radius: 1rem;
  padding-bottom: 1rem;
}
</style>
